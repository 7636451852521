import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
	open = false
	
	get dark(){
		if (!this.system.settings) return true
		else if (this.system.settings && this.system.settings.dark) return true
		else return false
		// return !document.body.classList.contains('dark');
	}


	constructor(
		public system : SystemService,
		public auth : AuthService ) { }

	ngOnInit(): void {

	}

	openMenu() {
        document.getElementById("mobile-menu")!.style.left = "0%";
    }
    
    closeMenu() {
        document.getElementById("mobile-menu")!.style.left = "-100%";
    }

	// login() {
	// 	this.closeMenu()
    //     const dialogRef =  this.dialog.open(LoginComponent, {
	// 		panelClass: 'secondary-modal'
	// 	})
    // }

    // register() {
	// 	this.closeMenu()
    //     const dialogRef =  this.dialog.open(RegisterComponent, {
	// 		panelClass: 'secondary-modal'
	// 	})
	// }

	// toggleMenu(){
	// 	if (this.open){
	// 		document.getElementById("app-sidebar")!.style.marginLeft = "-100%";
	// 		this.open = false
	// 	}
	// 	else {
	// 		document.getElementById("app-sidebar")!.style.marginLeft = "0%";
	// 		this.open = true
	// 	}
	// }


}
