import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "../_components/components.module";
import { MaterialModule } from "../_components/material.module";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { VideoPlayerComponent } from "./video-player/video-player.component";

const modals = [
    LoginComponent,
    RegisterComponent,
    VideoPlayerComponent
]

@NgModule({
    declarations: [
        ...modals
    ],
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        ComponentsModule
    ],
    exports: [
        ...modals
    ]
})
export class ModalsModule { }