import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CourseCardComponent } from "./course-card/course-card.component";
import { CourseFiltersComponent } from "./course-filters/course-filters.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { MetaGuideCardComponent } from "./metaguide-card/metaguide-card.component";
import { MobileMenuComponent } from "./mobile-menu/mobile-menu.component";
import { ReviewCardComponent } from "./review-card/review-card.component";
import { MetaGuideFiltersComponent } from "./metaguide-filters/metaguide-filters.component";
import { ImgLoaderComponent } from "./img-loader/img-loader.component";
import { NgxJdenticonModule } from "ngx-jdenticon";
import { ModalsModule } from "../_modals/modals.module";
import { QuizComponent } from "./quiz/quiz.component";
import { CourseOptionsComponent } from "./course-options/course-options.component";

const Components = [
    HeaderComponent,
    MobileMenuComponent,
    CourseCardComponent,
    MetaGuideCardComponent,
    CourseFiltersComponent,
    ReviewCardComponent,
    FooterComponent,
    MetaGuideFiltersComponent,
    ImgLoaderComponent,
    QuizComponent,
    CourseOptionsComponent
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxJdenticonModule,
    ],
    exports: [
        ...Components
    ],
    declarations: [
        ...Components
    ]
})
export class ComponentsModule{}