import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { API, DEFAULT_SETTINGS, IPFS_GATEWAY, IPFS_SUFFIX, WIRE_API } from '../_constants/constants';
import { AuthService } from './auth.service';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Browser } from '@capacitor/browser';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { HttpClient } from '@angular/common/http';
import { Camera, CameraResultType } from '@capacitor/camera';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginComponent } from '../_modals/login/login.component';
import { RegisterComponent } from '../_modals/register/register.component';
import { Share } from '@capacitor/share';
import { Course } from './course.service';

const urlRegex = new RegExp("/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i")

@Injectable()
export class SystemService {
    private events: any = {};

    public categories : string[] = [ 
		// 'ALL',
		// 'BLOCKCHAIN',
		// 'PLAY TO EARN',
		// 'NFTs',
		// 'CRYPTO',
		// 'WEB 3',
		// 'BUSINESS'
        'Blockchain',
        'Crypto',
        // 'Web3',
        'Development',
        'Marketing',
        'Business',
        'Photography',
        'Play To Earn',
        'Trading',
        'DeFi',
        'Security',
        'NFTs',
        'History'
	]



    public interests : string[] = [ 
		'Blockchain',
		'Play to Earn',
		'NFTs',
		'Crypto',
		'Web3',
		'Business'
	]

    public difficulty : string[] = [ 
		'Beginner',
		'Moderate',
		'Advanced',
		'Expert'
	]

    constructor(
        private auth: AuthService,
        private platform : Platform,
        private toast: ToastController,
        private modal: ModalController,
        private alert : AlertController,
        private load : LoadingController,
        private nav : NavController,
        private router: Router,
        private http : HttpClient,
        private clipboard : Clipboard,
		private sanitizer : DomSanitizer ){

        if (this.settings) this.toggleDark(this.settings.dark)
        else this.setSettings(DEFAULT_SETTINGS)
    }

    get mode(){
        return this.settings.dark ? 'dark' : 'light'
    }
    get modeInvert(){
        return this.settings.dark ? 'light' : 'dark'
    }

    get settings() {
        let settings = localStorage.getItem('meta-settings')
        if (!settings) return DEFAULT_SETTINGS
        else return JSON.parse(settings)
    }

    setSettings(settings : any) {
        localStorage.setItem('meta-settings', JSON.stringify(settings))
    }

    setKeyboard(dark: boolean) {
        if ((window as any).Capacitor.isPluginAvailable('Keyboard'))
            Keyboard.setStyle({ style: dark ? KeyboardStyle.Dark : KeyboardStyle.Light })
    }

    toggleDark(x? : boolean){
        let toggle : boolean = x ? x : !document.body.classList.contains('dark')
		document.body.classList.toggle('dark', toggle);
		this.setKeyboard(toggle)
        let settings = this.settings
        settings.dark = toggle
        this.setSettings(settings)
        this.emit('toggle-dark', toggle)
	}

    vibrate(style? : any) {
        if (!style) style = 'light'
        if ((window as any).Capacitor.isPluginAvailable('Haptics'))
            Haptics.impact({ style: style.toUpperCase() })
    }
    vibrateImpact() {
        if ((window as any).Capacitor.isPluginAvailable('Haptics'))
            Haptics.vibrate()
    }
    vibrateSelection() {
        if ((window as any).Capacitor.isPluginAvailable('Haptics')) {
            Haptics.selectionStart();
            Haptics.selectionChanged();
            Haptics.selectionEnd();
        }
    }
    hapticsSelectionStart() {
        if ((window as any).Capacitor.isPluginAvailable('Haptics'))
            Haptics.selectionStart();
    }
    hapticsSelectionChanged() {
        if ((window as any).Capacitor.isPluginAvailable('Haptics'))
            Haptics.selectionChanged();
    }
    hapticsSelectionEnd() {
        if ((window as any).Capacitor.isPluginAvailable('Haptics'))
            Haptics.selectionEnd();
    }

    async showToast(data: ToastData) {
        let buttons : any[] = []
        if (data.icon) buttons.push({ side: 'start', icon: data.icon })
        if (data.link) buttons.push({ 
            side: 'end', 
            // icon: 'arrow-redo',
            text: data.linkText ? data.linkText : 'VIEW', 
            handler: () => { window.open(data.link!, '_blank') } 
        })
        if (data.linkText?.toLowerCase() == 'login') buttons.push({ 
            side: 'end', 
            text: data.linkText,
            handler: async () => {
                const modal = await this.modal.create({
                    component: LoginComponent,
                    swipeToClose: true,
                    cssClass: 'main-modal'
                });
                return await modal.present(); 
            } 
        })
        if (data.linkText?.toLowerCase() == 'register') buttons.push({ 
            side: 'end', 
            text: data.linkText,
            handler: async () => {
                const modal = await this.modal.create({
                    component: RegisterComponent,
                    swipeToClose: true,
                    cssClass: 'main-modal'
                });
                return await modal.present(); 
            } 
        })

        const toast = await this.toast.create({
            header: data.header,
            message: data.message,
            duration: data.duration ? data.duration : 4000,
            position: 'bottom',
            color: data.color ? data.color : 'dark',
            cssClass: 'my-toast',
            buttons
        });
        toast.present();
        return toast;
    }

    async openLink(url: string) {
        this.vibrate()
        if (!url.includes('https://') && !url.includes('http://')) url = 'https://' + url
        await Browser.open({ toolbarColor: '#131725', url })
    }

    get isMobile(): boolean {
        return (this.platform.is('android') || this.platform.is('ios') && !this.platform.is('desktop'));
    }

    async copyClipboard(str : string, name? : string, showMessage : boolean = false){
        this.clipboard.copy(str).then((text: string) => {}, (reject: string) => {
            if (navigator.clipboard){
                try { navigator.clipboard.writeText(str).then(() => {}); }
                catch (err){ console.log("ERR"); }
            }
        });

        const toast = await this.toast.create({
            header: name ? `${name} copied to clipboard` : `Copied to clipboard`,
            message: showMessage ? str : undefined,
            duration: 2000,
            position: 'bottom',
            color: 'success',
            cssClass: 'my-toast-copy',
            buttons: [{ side: 'end', icon: 'copy-outline' }]
        });
        toast.present();
        return toast;
    }

    copied(str : string){
        this.showToast({ header: `${str} copied to clipboard`, color: 'success', icon: 'copy-outline'})
    }

    ipfs(cid : string){
        return IPFS_GATEWAY + cid + IPFS_SUFFIX
    }

    async takePicture(fullUrl? : boolean, ) : Promise<string> {
        return new Promise(async (resolve, reject)=>{
            const image = await Camera.getPhoto({
                quality: 100,
                allowEditing: false,
                resultType: CameraResultType.Base64,
            });
            let blob = this.b64toBlob(image.base64String, `image/${image.format}`);
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = async (e) => {
                let url = reader.result as string;
                let message = `<img src="${url}" class="profile-xl light-border">`
                let header = "Upload File to IPFS"

                const alert = await this.alert.create({
                    cssClass: 'custom-alert',
                    header,
                    message,
                    buttons: [
                        {
                            text: 'Upload',
                            handler: () => {
                                this.alert.dismiss()
                                
                                this.uploadFile(blob).then((cid : string)=>{
                                    // console.log(cid);
                                    resolve(fullUrl ? IPFS_GATEWAY + cid : cid)
                                })
                            }
                        },
                        {
                            text: 'Cancel',
                            role: 'cancel'
                        }, 
                    ],
                    translucent: true
                });
                await alert.present();
            };
        })
    }

    async uploadFile(blob : Blob, noLoad? : boolean, title: string = 'file', ): Promise<string>{
        let loading : any
        if (noLoad){
            loading = await this.load.create({ spinner: 'crescent', message: `Uploading ${title} to IPFS...`, cssClass: 'loading-overlay', backdropDismiss: false });
            await loading.present();
        }

        return new Promise((resolve, reject) => {
            let data = new FormData();
            data.append('file', blob, 'img');
            this.http.post(WIRE_API + "addFileSimple", data).subscribe((res : any) => {
                // console.log(res);
                if (loading) loading.dismiss()
                if (res.cid) resolve(res.cid)
                else (reject("Something went wrong..."))
            }, (err:any) => {
                if (loading) loading.dismiss()
                console.log(err);
                reject(err)
            })
        })
    }

    b64toBlob(b64Data : any, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
     
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
        
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
     
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    social(link : string){
        if (link.includes('facebook') || link.includes('fb.me')) return 'facebook'
        else if (link.includes('twitter')) return 'twitter'
        else if (link.includes('discord')) return 'discord'
        else if (link.includes('youtube')) return 'youtube'
        else if (link.includes('instagram')) return 'instagram'
        else if (link.includes('linkedin')) return 'linkedin'
        else if (link.includes('medium')) return 'medium'
        else if (link.includes('twitch')) return 'twitch'
        else return 'link'
    }
    socialIcon(link : string){
        switch(this.social(link)){
            case 'facebook' :   return 'fa-brands fa-facebook-f';   break;
            case 'twitter' :    return 'fa-brands fa-twitter';      break;
            case 'discord' :    return 'fa-brands fa-discord';      break;
            case 'youtube' :    return 'fa-brands fa-youtube';      break;
            case 'instagram' :  return 'fa-brands fa-instagram';    break;
            case 'linkedin' :   return 'fa-brands fa-linkedin-in';  break;
            case 'medium' :     return 'fa-brands fa-medium';       break;
            case 'twitch' :     return 'fa-brands fa-twitch';       break;
            case 'link' :       return 'fa-solid fa-link';          break;
        }
    }
    validURL(link : string): boolean {
        // return urlRegex.test(link)
        let url 
        try { url = new URL(link); } 
        catch (err) { return false; }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    courseStatus(status : -1 | 0 | 1 | 2){
        switch(status){
            case -1 : return 'Denied';   break;
            case 0 :  return 'Draft';    break;
            case 1 :  return 'Pending';  break;
            case 2 :  return 'Approved'; break;
        }
    }
    courseStatusColor(status : -1 | 0 | 1 | 2){
        switch(status){
            case -1 : return 'danger';   break;
            case 0 :  return 'primary';    break;
            case 1 :  return 'warning';  break;
            case 2 :  return 'success'; break;
        }
    }

    formatBytes(bytes : number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    timeFormat(duration : number){   
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = Math.ceil(duration % 60);
        var ret = "";
        if (hrs > 0) ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    timeFormatLong(duration : number): Array<string>{
        let split = this.timeFormat(duration).split(':')
        return split.reverse()
    }

    sanitize(url : string){
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
    safeSanitize(url : string){
        return url.startsWith('blob') ? this.sanitizer.bypassSecurityTrustUrl(url) : url
    }
	async shareCourse(course : Course){
        try {
            await Share.share({
                title: `Metakademy Course: ${course.title} by ${course.metaguideName}`,
                text: `Metakademy Course: ${course.title} by ${course.metaguideName}`,
                url: `https://metakademy.com/courses/${course.course_id}`,
                dialogTitle: 'Share',
            });
        }
        catch { 
            this.copyClipboard(`https://metakademy.com/courses/${course.course_id}`) 
        }
	}

    on(event: string) : any {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)

        else this.events[event] = [sub]
        return sub
    }
    emit(event: string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}


export interface ToastData {
    header: string;
    message?: string;
    icon?: string;
    link?: string;
    linkText?: string;
    duration?: number;
    color?: string;
    route? : string;
}