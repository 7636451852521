import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { AwsService } from 'src/app/_services/aws.service';
import { CourseService, SqlLecture } from 'src/app/_services/course.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
    @Input() lecture! : SqlLecture

    videoLoading = true

    signature? : string

    constructor(
        private aws : AwsService,
        private course : CourseService,
        private load : LoadingController,
        private modal : ModalController,
        public auth : AuthService,
        private system : SystemService ){

    }

    ngOnInit() {
        this.auth.generateSignature().then((signature : string)=>{
            this.signature = signature

            setTimeout(()=>{
                document.querySelector('video')!.addEventListener('ended', (event) => {
                    // console.log('VIDEO ENDED');
                    this.reward()
                });
            }, 1000)
        })
        
        // this.aws.getFile(this.lecture.aws_key).then((blob : Blob)=>{
        //     this.videoLoading = false
        //     var urlCreator = window.URL || window.webkitURL;
		// 	var url = urlCreator.createObjectURL(blob);
		// 	let x : any = document.getElementById("video")
		// 	x!.src = url;
        // })


    }

    async reward(){
        const loading = await this.load.create({ spinner: 'crescent', message: `Rewarding...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();
        this.course.watchLecture(this.lecture.course_id, this.lecture.lecture_id).then((res : any)=>{
            setTimeout(()=>{
                loading.dismiss()
                let message = res.multiplier ? `Rewards: ${res.reward} (2x MetaKey Holder Multiplier)` : `Rewards: ${res.reward}`
                this.system.showToast({ header: 'Rewards granted!', message, color: 'success', icon: 'trophy'})
                this.modal.dismiss(true)
            }, 200)
        }, err => {
            console.log(err);
        })
    }
}
