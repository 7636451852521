import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Readable } from "stream";
import { API } from "../_constants/constants";
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, timestamp } from 'rxjs/operators';
import { FormLecture } from "../edit-course/edit-course.component";
import { AuthService } from "./auth.service";

const httpOptions: any = {
    responseType: 'blob',
    headers: new HttpHeaders ({
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*'
    })
}

@Injectable()
export class AwsService {
    private events : any = {};

    uploads : UploadMedia[] = []
    uploadLimit = 2

    constructor(public http : HttpClient, public auth : AuthService){

    }

    getFile(filename: string): Promise<Blob>{
        return new Promise((resolve, reject)=>{
            this.http.get<any>(API + 'getFile/' + filename).subscribe(async (blob: any)=>{
                resolve(blob as Blob);
            }, err => reject(err) )
        })
    }

    uploadFile(file : File, course_id : string, filename : string){
        return new Promise((resolve, reject)=>{
            let data = new FormData();
            data.append('file', file, 'img');
            data.append('name', filename);
            data.append('directory', course_id);
            
            this.http.post(API + 'uploadFile', data).subscribe((res)=>{
                resolve(res)
            }, err => reject(err))
        })
    }

    get uploadComplete() { return this.uploads.filter(x => x.started && x.completed ).length == this.uploads.length }
    get uploadActive()   { return this.uploads.filter(x => x.started && !x.completed ).length }
    get myUploads()     { return this.uploads }

    // uploadFiles(files: File[]): Observable<HttpEvent<any>>{
    uploadLectures(lectures: FormLecture[], course_id : number){
        // console.log('Uploading lectures:', lectures);

        let id = 1
        for (let lecture of lectures){
            let upload : UploadStatus = {
                id: id++,
                file : lecture.media!,
                title : lecture.title,
                description: lecture.description,
                rewards: lecture.rewards ? lecture.rewards : 0,
                duration : lecture.duration,
                course_id
            }
            this.uploads.push(new UploadMedia(this, upload))
        }

        for (let i = 0; i < this.uploadLimit; i++) if (this.uploads[i]) this.uploads[i].upload()
    }

    updateLectures(lectures: FormLecture[], course_id : number){
        return new Promise((resolve, reject)=>{

            let updates = []
            let id = 1
            for (let lecture of lectures){
                updates.push({
                    id: id++,
                    title : lecture.title,
                    description: lecture.description,
                    course_id
                })
            }
            
            this.http.post(API + 'simpleUpdateLecture', { updates }, {
                headers: new HttpHeaders ({ 
                    'Accept': '*/*',
                    'username': this.auth.user.username,
                    'signature': this.auth.authSignature!,
                }),
            })
            .subscribe((data:any)=>{
                // console.log(data);
                resolve(true)
            }, err => {
                console.log("ERR", err);
            })
        })
    }

    singleUploadComplete(){
        let remaining : UploadMedia[] = this.uploads.filter(x => !x.started)

        if (remaining.length){
            console.log(remaining.length, 'files left');
            remaining[0].upload()
        }
        else {
            console.log('DONE');
            // return??
        }
    }

    delay(ms: number) {
        return new Promise((resolve) => { setTimeout(() => { resolve(true); }, ms) })
    }

    on(event: string) : any {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)

        else this.events[event] = [sub]
        return sub
    }
    emit(event: string, data?: any) : any {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export class UploadMedia {
    progress : number = 0
    completed : boolean = false
    started : boolean = false

    constructor(
        private parent : AwsService, 
        private status : UploadStatus,
        // private update : boolean
         ){}

    upload(){
        this.started = true

        let data = new FormData();

        // if (this.status.file) {
            
            let filename = `${this.status.id}_${this.status.title.replace(/ /g, "_")}.${this.status.file.type.split('/')[1]}`
            data.append('file', this.status.file, 'mp4');
            data.append('filename', filename);
            data.append('title', this.status.title);
            data.append('course_id', this.status.course_id.toString());
            data.append('lecture_id', this.status.id.toString());
            data.append('description', this.status.description);
            data.append('rewards', this.status.rewards.toString());
            data.append('metaguide', this.parent.auth.user.username);
            data.append('duration', this.status.duration.toString());

            this.parent.http.post(API + 'storeLecture', data, {
                headers: new HttpHeaders ({ 
                    'Accept': '*/*',
                    'username': this.parent.auth.user.username,
                    'signature': this.parent.auth.authSignature!,
                }),
                reportProgress: true,
                observe: "events"
            })
            .subscribe((data:any)=>{
                // console.log(data);
                if (data.type == 1){
                    this.progress = +((data.loaded / data.total)).toFixed(4)
                }
                else if (data.type == 4){
                    console.log('File upload success!');
                    this.completed = true
                    this.parent.singleUploadComplete()
                    this.parent.emit('uploaded-course', this.status.id)
                }
                
            }, err => {
                console.log("ERR", err);
            })
        // }
        // else {
        //     console.log('update simple');

        //     let data = {
        //         title : this.status.title,
        //         course_id : this.status.course_id.toString(),
        //         lecture_id : this.status.id.toString(),
        //         description : this.status.description,
        //         rewards : this.status.rewards.toString(),
        //     }
            
        //     this.parent.http.post(API + 'simpleUpdateLecture', data, {
        //         headers: new HttpHeaders ({ 
        //             'Accept': '*/*',
        //             'username': this.parent.auth.user.username,
        //             'signature': this.parent.auth.authSignature!,
        //         }),
        //     })
        //     .subscribe((data:any)=>{
        //         console.log(data);
        //     }, err => {
        //         console.log("ERR", err);
        //     })
        // }
    }
}

export interface SqlCourse {
    course_id : number
    title : string
    metaguide : string
    profilePic : string
    thumbnail : string
    categories : string[]
    rating : number
    duration : number
    numLectures : number
	overview: string
	difficulty: string
    lectures : SqlLecture[]
    quiz : SqlQuiz[]
}
export interface SqlLecture {
	title : string
	media : File | undefined
	duration : number
}
export interface SqlQuiz {

}

export interface UploadStatus {
    id : number
    file : File
    title : string
    description: string
    rewards : number
    duration : number
    course_id : number
    // progress : number
    // started : boolean
    // completed : boolean
}

export interface AwsUploadStatusObj {
    [key: string]: AwsUploadStatus
}
export interface AwsUploadStatus {
    progress : number
    completed : boolean
    // started : boolean
}

