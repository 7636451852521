import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { Key } from "./crypto.service";
import { DEFAULT_SETTINGS, API, AES_KEY, METAKADEMY_ACTIVE_PUBLIC } from '../_constants/constants';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { AlertController } from "@ionic/angular";
const ecc = require('eosjs-ecc');
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthService  {
    private events : any = {};
    public authHeaders? : HttpHeaders
    public authSignature? : string

    constructor(
        private storage : Storage,
        private alert : AlertController,
        private http : HttpClient ){

        this.storage.create()
        if (this.user && !this.authHeaders) this.setAuthHeaders()

    }

    get user(): User {
        return localStorage.getItem('meta-user') ? JSON.parse(localStorage.getItem('meta-user')!) : null
    }
    get authHeader() : any {
        return this.authHeaders ? { headers: this.authHeaders } : { headers: new HttpHeaders() }
    }
    get address(): string {
        let a = localStorage.getItem('address')
        return a ? a : ''
    }
    get addressShort(): string {
        let a = this.address;
        return a ? a.substring(0, 6) + '...' + a.substring(a.length - 4) : '';
    }
    get addressShort2(): string {
        let a = this.address;
        return a ? a.substring(2, 6) + '...' + a.substring(a.length - 4) : '';
    }
    get settings() {
        let settings = localStorage.getItem('meta-settings')
        if (!settings) return DEFAULT_SETTINGS
        else return JSON.parse(settings)
    }
    get signature(){
        return this.authSignature
    }


    setSettings(settings : any) {
        localStorage.setItem('meta-settings', JSON.stringify(settings))
    }
    setUser(user : User) {
        localStorage.setItem('meta-user', JSON.stringify(user))
    }

    login(user : Account){
        if (user.address) localStorage.setItem('address', user.address);
        let str = JSON.stringify(user)
        localStorage.setItem('meta-user', str);

        setTimeout(()=>{
            this.setAuthHeaders()
        }, 1000)
    }
    
    async logout() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Logout',
            message: `Are you sure you want to log out?`,
            buttons: [{
                text: 'Logout',
                handler: () => {
                    this.emit("logout")
                    this.clearStorage();
                    localStorage.setItem('meta-settings', JSON.stringify(DEFAULT_SETTINGS))
                }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        });
        alert.present();
    }

    register(){

    }

    setKey(key : Key){
        this.storage.set('key', CryptoJS.AES.encrypt(JSON.stringify(key), AES_KEY).toString())
    }
    getKey() : Promise<Key>{
        return new Promise((res, rej) => {
            this.storage.get('key').then((key) => {
                if (key){
                    try         { res(JSON.parse(CryptoJS.AES.decrypt(key, AES_KEY).toString(CryptoJS.enc.Utf8))) } 
                    catch(err)  { console.log("ERR", err) }
                }
            });
        })
    }

    setAuthHeaders(username? : string){
        return new Promise((resolve, reject)=>{
            this.generateSignature().then((signature : string)=>{
                this.authSignature = signature
                this.authHeaders = new HttpHeaders ({
                    'username': username ? username : this.user.username,
                    'signature': signature
                }) 
                resolve(this.authHeaders)
            })
        })
    }
    
    getNonce(username: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.http.get(`${API}get-nonce`, { params: {username }}).toPromise().then((response: any) => {
                if(response.nonce) resolve(response.nonce);
            }).catch((err) => {
                reject(err);
            })
        })
    }
    generateSignature() : Promise<string>{
        return new Promise((resolve, reject)=>{
            if (this.user) this.getKey().then((res: Key)=>{
                resolve(ecc.sign(this.user.username, res.priv_key))
            }, err => reject('Error getting keys'))
            else reject('Not logged in')
        })
    }

    // encryptSign(str : string){
    //     return ecc.sign(str, METAKADEMY_ACTIVE_PUBLIC).toString()
    // }

    registerMetamask(message: string, username: string, email : string) {
        return new Promise((resolve, reject) => {
            this.http.post(`${API}register-metamask`, { message, username, email }).toPromise().then((data) => {
                let response = <HTTPResponse<Array<any>>>data;
                resolve(response);
            }).catch((err) => {
                console.log('HTTP Error', err);
                reject(err)
            })
        })
    }

    metaKeyHolder(usernameOrAddress : string) : Promise<boolean>{
        return new Promise((resolve, reject)=>{
            this.http.get(API + 'metaKeyHolder/' + usernameOrAddress).subscribe((res : any)=>{
                resolve(res.metaKeyHolder)
            }, err => reject(err))
        })
    }

    sendEmail(email : string){
        return new Promise((resolve, reject)=>{
            this.http.post(API + "sendEmail", { email }).subscribe((res:any) => {
                resolve(res)
            }, err => reject(err))
        })
    }

    clearStorage(){
        this.storage.clear()
        localStorage.removeItem('meta-user')
        localStorage.removeItem('meta-settings')
        localStorage.removeItem('address')
        this.authHeaders = undefined
        this.authSignature = undefined
    }


    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
}

export interface User {
    username : string
    name : string
    title : string
    profilePic : string
    bio : string
    interests : string
    favorited : number
    favorites : number
    numCourses : number
    updated : any
}
interface HTTPResponse<T> {
    error: boolean;
    data?: T;
    message?: string;
}


export interface Account {
    username: string
    email: string
    address?: string
    key? : string
}