import { Component, Input, OnInit } from '@angular/core';
import { Review } from 'src/app/_services/course.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {
	@Input() review! : Review
	@Input() outline? : boolean

	get wholeStars() : number {
		return Math.floor(this.review.rating)
	}
	get halfStar() : boolean {
		return this.review.rating > Math.floor(this.review.rating)
	}

	constructor(public system : SystemService){}

	ngOnInit(){
		// console.log(this.course);
	}

}
