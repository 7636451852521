import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API, discord_auth_url } from "../_constants/constants";
import { AuthService } from "./auth.service";
import sha256 from "crypto-js/sha256";
// import { DiscordAuthModal } from "../_modals/discord-auth/discord-auth.modal";
import { ModalController } from "@ionic/angular";
const ecc = require('eosjs-ecc');

@Injectable()
export class DiscordService {
    constructor(
        private auth : AuthService,
        private http : HttpClient,
        private modal : ModalController) {

        // if (this.auth.user && !this.user) this.login(this.auth.user.username)
    }

    get user(): DiscordMember {
        return localStorage.getItem('discord-user') ? JSON.parse(localStorage.getItem('discord-user')!) : null
    }
    setUser(user: DiscordMember) {
        localStorage.setItem('discord-user', JSON.stringify(user));
    }

    get asked() : boolean {
        return localStorage.getItem('asked') ? true : false
    }
    didAsk(){
        localStorage.setItem('asked', JSON.stringify({ asked : true }));
    }

    get roles(): string[] {
        let user : DiscordMember = localStorage.getItem('discord-user') ? JSON.parse(localStorage.getItem('discord-user')!) : null
        if (user) return user.roles!.map((role : DiscordGuildRole)=>{ return role.name.toLowerCase() })
        else      return []
    }
    get roleGroups(): string[]{
        return this.roles.map((x)=>{ return 'ds.' + x })
    }

    login(username : string){
        // return new Promise(async (resolve, reject) => {
            this.http.get(API + 'discord-username/' + username).subscribe((res : any)=>{
                this.setUser(res.data)
            }, err => {
                // console.log("Not linked yet");
                // setTimeout(()=>{
                //     if (!this.asked && this.dialog.openDialogs.length == 0){
                //         // console.log("Not asked yet");
                //         const ref = this.dialog.open(DiscordAuthModal, {
                //             panelClass: 'width-modal'
                //         })
                //         ref.afterClosed().subscribe(()=>{
                //             this.didAsk()
                //         })
                //     }
                // }, 1000)
            })
        // })
    }

    connect(extension? : string){
        // let url = extension ? environment.discord_auth_url.replace('app.dragonspawn.com', `app.dragonspawn.com%2F${extension}`) : environment.discord_auth_url
        window.open(discord_auth_url, '_blank')
    }

    getDiscordAccounts(): Promise<Array<DiscordMember>> {
        return new Promise(async (resolve, reject) => {
            if(this.auth.user && this.auth.address) {
                await (this.http.get(`${API}connected-accounts`, {
                    params: {
                        address: this.auth.address
                    }
                }).toPromise()).then((data) => {
                    let response = <HTTPResponse<Array<DiscordMember>>>data;
                    if(!response.error && response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.message);
                    }
                }).catch((err) => {
                    console.log('HTTP error', err);
                })
            }
        })
    }

    connectDiscordAccount(code: string): Promise<DiscordMember> {
        return new Promise(async (resolve, reject) => {
            if (!this.auth.user) reject({ error: 'Not logged in' });
            // if (!this.auth.address || this.auth.address == '') reject({ error: 'Address not connected' });

            let body = { username: this.auth.user?.username, code, address: this.auth.address ? this.auth.address : ''}
            // let auth = await this.signUser(body, this.auth.user?.username);

            this.http.post(API + 'register-discord', body, this.auth.authHeader).subscribe((res : any)=>{
                // console.log("USER REGISTERED", res.data);
                this.setUser(res.data)
                resolve(res.data)
            }, err => reject(err.error))

            // this.http.post(`${API}register-discord`, body, {
            //     headers: { userauth: auth }
            // }).toPromise().then((data) => {
            //     let response = <HTTPResponse<Array<any>>>data;
            //     resolve(response);
            // }).catch((err) => {
            //     console.log('HTTP Error', err);
            //     reject(err)
            // })
        })
    }

    removeDiscordAccount(id: string): Promise<HTTPResponse<Array<any>>> {
        return new Promise(async (resolve, reject) => {
            if(this.auth.address && this.auth.address != '') {
                let body = {
                    id,
                    address: this.auth.address
                }

                let auth = await this.signUser(body, this.auth.user.username);

                this.http.post(`${API}remove-discord`, body, {
                    headers: {
                        userauth: auth
                    }
                }).toPromise().then((data) => {
                    let response = <HTTPResponse<Array<any>>>data;
                    resolve(response);
                }).catch((err) => {
                    console.log('HTTP Error', err);
                })
            } else {
                reject('Not logged in or missing address');
            }
        })
    }

    checkClaims(): Promise<HTTPResponse<Array<any>>> {
        return new Promise(async (resolve, reject) => {
            if(this.auth.address && this.auth.address != '') {
                let body = {
                    address: this.auth.address
                }

                let auth = await this.signUser(body, this.auth.user.username);

                this.http.post(`${API}check-claims`, body, {
                    headers: {
                        userauth: auth
                    }
                }).toPromise().then((data) => {
                    let response = <HTTPResponse<Array<any>>>data;
                    resolve(response);
                }).catch((err) => {
                    console.log('HTTP Error', err);
                })
            } else {
                reject('Not logged in or missing address');
            }
        })
    }

    async signUser(body: any, username: string): Promise<string> {
        let str: string = '';
        try {
            str = JSON.stringify(body)
        } catch(err) {
            console.log('Body not JSON');
            if(typeof body == 'string') str = body;
        }

        let hash: string = sha256(str).toString();
        // console.log('Hash', hash);
        
        //console.log("Username Hash:", hash);
        let keys = await this.auth.getKey();

        if(keys) {
            let signature = ecc.signHash(hash, keys.priv_key);
            // console.log('Public Key:', keys.pub_key);
            
            // console.log("Signature:", signature);
            let verified = ecc.verifyHash(signature, hash, keys.pub_key);
            // console.log("Verified:", verified);
            return `${username}/${signature}`;
        } else {
            return '';
        }
    }
}


interface HTTPResponse<T> {
    error: boolean;
    data?: T;
    message?: string;
}

export interface DiscordUser {
    id: number,
    username: string,
    avatar: string,
    avatar_decoration: any,
    discriminator: number,
    public_flags: number
}

export interface DiscordMember {
    id: string;
    username: string;
    avatar: string | null;
    banner: string | null | undefined;
    in_guild: boolean;
    roles?: DiscordGuildRole[];
}

export interface DiscordGuildRole {
    icon: string | null,
    unicodeEmoji: string | null,
    id: string,
    name: string,
    color: string,
}