import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthService } from 'src/app/_services/auth.service';
import { CourseService, QuizQuestion, QuizResponse, SqlQuiz } from 'src/app/_services/course.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
	selector: 'app-quiz',
	templateUrl: './quiz.component.html',
	styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
	@Input() course_id! : number

	quiz : QuizQuestion[] = []

	started = false

	timer : any = null

	responses : any = {}

	quizResponse? : QuizResponse 

	loading = true
	noQuiz = false

	get canSubmit() : boolean {
		for (let q of this.quiz) 
			if (!this.responses.hasOwnProperty(q.question_id) || q.num_correct != this.responses[q.question_id].length) 
				return false
		return true
	}
	get totalQuestions(){
		if (this.quizResponse) return JSON.parse(this.quizResponse.answers).length
		else return 0
	}
	get totalCorrect(){
		if (!this.quizResponse || !this.quizResponse.correct || this.quizResponse.correct == '') return 0
		else return this.quizResponse.correct.split(',').length
	}
	get dateCompleted(){
		if (!this.quizResponse) return ''
		else return new Date(this.quizResponse.started)
	}
	get duration(){
		if (!this.quizResponse) return 0
		else return Math.abs(new Date(this.quizResponse.started).getTime() - new Date(this.quizResponse.finished).getTime()) / 1000;
	}

	constructor(
		public course : CourseService,
		public system : SystemService,
		private alert : AlertController,
		public auth : AuthService,
		private load : LoadingController ){

		if (this.started && this.course.activeQuiz && this.course.activeQuiz.course_id == this.course_id)
			this.timer = this.system.timeFormat( ((new Date().getTime()) - (this.course.activeQuiz!.started.getTime()))/1000)

		setInterval(() =>{
			if (this.started && this.course.activeQuiz)
				this.timer = this.system.timeFormat( ((new Date().getTime()) - (this.course.activeQuiz!.started.getTime()))/1000)
		}, 1000)
	}

	ngOnInit() {
		if (this.course.activeQuiz && this.course.activeQuiz.course_id == this.course_id){
			this.loading = false
			this.started = true
			this.getQuiz()
		}
		else this.checkResponse()


		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			this.getQuiz()
		}
	}

	checkResponse(){
		this.course.getQuizResponse(this.course_id).then((res : any)=>{
			if (res){
				this.quizResponse = res
				this.course.getQuiz(this.course_id).then((quiz : SqlQuiz[])=>{
					this.quiz = this.course.formatSqlQuiz(quiz)
					this.loading = false
				}, err =>{
					this.loading = false
					this.system.showToast({ header: 'Error loading quiz:', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
				});
			}
			else this.getQuiz()
		}, err => {
			console.log('No quiz response');
			this.loading = false
		})
	}

	getQuiz(){
		this.loading = true
		this.course.getQuiz(this.course_id).then((quiz : SqlQuiz[])=>{
			for (let q of quiz) 
				this.responses[q.question_id] = 
					this.course.quizAnswers.hasOwnProperty(q.question_id) ? this.course.quizAnswers[q.question_id] : []
			
			this.quiz = this.course.formatSqlQuiz(quiz)
			this.loading = false
		}, err =>{
			console.log('No quiz yet');
			this.noQuiz = true
		});
	}

	startQuiz(){
		this.started = true
		this.course.startQuiz(this.course_id)
		this.getQuiz()
	}

	checkbox(e : any, i : number, answer_key : number){
		if (e.target.checked) this.responses[i+1].push(answer_key)
		else                  this.responses[i+1] = this.responses[i+1].filter((x:any) => x != answer_key )
		this.course.quizAnswers = this.responses
	}

	async confirmSubmit() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'Confirm Submit',
            message: `Are you sure you want to submit this quiz?`,
            buttons: [{
                text: 'Submit',
                handler: async () => { this.submit() }
            },{
                text: 'Cancel',
                role: 'cancel',
            }]
        }); alert.present();
    }

	async submit(){
		this.loading = true

		const loading = await this.load.create({ spinner: 'crescent', message: `Submitting...`, cssClass: 'loading-overlay', backdropDismiss: false });
		await loading.present();

		this.course.submitQuiz().then((res : QuizResponse)=>{
			// console.log(res);
			this.quizResponse = res
			loading.dismiss()
			this.loading = false
		}, err => {
			loading.dismiss()
			this.loading = false
			this.system.showToast({ header: 'Error submitting quiz...', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
		})
	}

	

}
