import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { LoginComponent } from 'src/app/_modals/login/login.component';
import { RegisterComponent } from 'src/app/_modals/register/register.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ConnectService } from 'src/app/_services/connect.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	

	get dark(){
		if (!this.system.settings) return true
		else if (this.system.settings && this.system.settings.dark) return true
		else return false
		// return !document.body.classList.contains('dark');
	}

	constructor(
		public auth : AuthService,
		public system : SystemService,
		private modal : ModalController,
		private alert : AlertController,
		private connect : ConnectService
	) { }

	ngOnInit() {
		// this.connect()
	}


	openMenu(){
		document.getElementById("mobile-menu")!.style.left = "0%";
	}
	
	async openLogin(){
		const modal = await this.modal.create({
			component: LoginComponent,
			swipeToClose: true,
			cssClass: 'main-modal'
		});
		return await modal.present(); 
	}

    async openRegister(){
		const modal = await this.modal.create({
			component: RegisterComponent,
			swipeToClose: true,
			cssClass: 'main-modal'
		});
		return await modal.present(); 
	}

	async accountOptions() {
        // const alert = await this.alert.create({
        //     cssClass: 'my-custom-class',
        //     header: 'MetaMask Options',
        //     message: `Link a different account via MetaMask, or Disconnect from MetaMask.
        //     <br><br>Linked Address:<br><span class="courier light-blue">${this.auth.address}</span>`,
        //     buttons: [
        //         {
        //             text: 'Link Different',
        //             handler: () => { this.connect.editAccounts() }
        //         }, 
        //         {
        //             text: 'Disconnect',
        //             handler: () => { this.connect.disconnect() }
        //         }
        //     ]
        // });
        // await alert.present();
    }
}

