import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    },
    {
        path: 'courses',
        loadChildren: () => import('./courses/courses.module').then( m => m.CoursesPageModule)
    },
    {
        path: 'metaguides',
        loadChildren: () => import('./metaguides/metaguides.module').then( m => m.MetaGuidesPageModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
    },
    // {
    //     path: 'edit-course',
    //     loadChildren: () => import('./edit-course/edit-course.module').then( m => m.EditCourseModule)
    // },
    // {
    //     path: 'edit-course/:course_id',
    //     loadChildren: () => import('./edit-course/edit-course.module').then( m => m.EditCourseModule)
    // },
    {
        path: 'create-course',
        loadChildren: () => import('./edit-course/edit-course.module').then( m => m.EditCourseModule)
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
    {   path: '**', 
        redirectTo: ''
    }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
