import { Injectable } from "@angular/core";
import { API } from "../_constants/constants";
import { io, Socket, SocketOptions } from 'socket.io-client';

@Injectable()
export class SocketService {
    connected: boolean = false;

    public io : Socket;
    public roomId = '';

    constructor(){ this.io = io(API); }
}