import { Component } from '@angular/core';
import { AuthService } from './_services/auth.service';
import { SocketService } from './_services/socket.service';
import { SystemService } from './_services/system.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    
    constructor(
        private auth : AuthService,
        private system : SystemService,
        private socket : SocketService ){

            if (this.auth.user)
                this.socket.io.emit('auth', {  username: this.auth.user.username })
        }

}
