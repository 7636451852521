import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/_services/system.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	socials = [
		'https://twitter.com/themetakey',
		'https://discord.com/invite/metakey',
		'https://www.youtube.com/channel/UC46Ux6N7c6w5WUWDxbLxZ6A',
		'https://www.twitch.tv/themetakey',
		'https://medium.com/@Metakey',
	]

	constructor(
		public system : SystemService
	) { }

	ngOnInit() {}

	}
