import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Name, Action, Serializer, PublicKey } from '@greymass/eosio';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { API, WIRE_API } from '../../_constants/constants';

import { SystemService } from '../../_services/system.service';
import { Account, AuthService, User } from '../../_services/auth.service';
import { CryptoService, Key } from '../../_services/crypto.service';
import { ContractService } from '../../_services/contract.service';
import { ConnectService, MetamaskError } from "../../_services/connect.service";

import { LoginComponent } from '../login/login.component';

import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

const eosjsAccountName = require('eosjs-account-name');
const md5 = require('md5');

const usernameRegex = new RegExp("[^a-z1-5]+")

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    @Input() action?: string
    
    username: string = ''
    email: string = ''
    password: string = ''
    address: string = ''

    hidePassword = true

    // get usernameError() {
    //     if (this.username){
    //         return 'has username'
    //     }
    //     else {
    //         return null
    //     }
    // }

    get canRegister(){
        return this.username != '' && this.email != '' && this.password != '' && this.connect.connected && this.connect.accounts
    }

    constructor(
        private contract : ContractService,
        public connect: ConnectService,
        private alert: AlertController,
        private crypto : CryptoService,
        public system: SystemService,
        private auth: AuthService,
        private load: LoadingController,

        public modal: ModalController,
        public modal2: ModalController,
        private nav : NavController,
        private http: HttpClient) {

    }

    ngOnInit() {
        // this.auth.getKey().then((res)=>{console.log(res);
        // })
        // let ethereum = (window as any).ethereum;
        // if (typeof ethereum == 'undefined') this.tabIndex = 1 
        // else this.connect.connect()

        // this.connect.on('accountsChanged').subscribe((accounts: any) => {
        //     this.localKeyFromMetamask = null;
        // });

        // this.auth.on('address-set').subscribe((res : any)=>{
        //     this.address = res
        // })
        // this.auth.on('remove-address').subscribe((res : any)=>{
        //     console.log('remove');
            
        //     this.address = ''
        // })

        // if (this.connect.connected) this.address = this.connect.accounts[0]
    }

    clickAddress(){
        if (this.connect.connected) this.accountOptions()
        else this.connectMetamask()
    }

    async connectMetamask() {
        if (!(window as any).ethereum) {
            // Not Metamask supported browser
            const alert = await this.alert.create({
                cssClass: 'my-custom-class',
                header: 'Open Metamask',
                message: `This browser doesn't support Metamask. Click to continue with Metamask browser`,
                buttons: [{
                    text: 'Continue',
                    handler: () => {
                        let url = 'https://metamask.app.link/dapp/app.dragonspawn.com'
                        window.open(url, '_blank')
                    }
                }, {
                    text: 'Cancel',
                    role: 'cancel'
                }]
            });
            await alert.present();
        }
        else this.connect.connect()
    }

    async accountOptions() {
        const alert = await this.alert.create({
            cssClass: 'my-custom-class',
            header: 'MetaMask Options',
            message: `Link a different account via MetaMask, or Disconnect from MetaMask.
            <br><br>Linked Address:<br><span class="courier light-blue">${this.connect.accounts[0]}</span>`,
            buttons: [
                {
                    text: 'Link Different',
                    handler: () => { this.connect.editAccounts() }
                }, 
                {
                    text: 'Disconnect',
                    handler: () => { this.connect.disconnect() }
                }
            ]
        });
        await alert.present();
    }

    // USERNAME
    // checkUsername(x: FormControl, username?: string) {
        // let name = username ? username : x.value
        // this.valid_username = false

        // if (name) {
        //     if (usernameRegex.test(name)) this.username_error = "Can only use lowercase letters and numbers 1-5"
        //     else if (name.length > 12) this.username_error = "Must be less than 12 characters"
        //     else if (this.takenUsernames!.includes(name)) this.username_error = "Username is already taken"
        //     else this.valid_username = true
        // }
        // else this.username_error = "Username required"

        // return this.valid_username ? null : { checkUsername: { valid: false } };
    // }

    async register(){
        const loading = await this.load.create({ spinner: 'crescent', message: `Registering...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        this.username = this.username.toLowerCase()

        try {
            this.crypto.generateKeySeed(this.username, this.password).then((key : Key) => {
                let account : Account = {
                    username: this.username.toLowerCase(),
                    email: this.email,
                    address: this.connect.accounts[0],
                    key: key.pub_key
                }

                this.http.post(API + "register", account).subscribe((res:any) => {
                    // console.log(res);
                    loading.dismiss()
                    delete account.key
                    this.auth.setKey(key)
                    this.auth.login(account)
                    this.contract.login(key)
                    // this.discord.login(user.username)
                    // this.router.navigateByUrl('/home');
                    this.modal.dismiss()
                    this.auth.emit('login', account.username);
                    this.nav.navigateForward(['dashboard', 'settings'])

                    setTimeout(() =>{
                        this.system.showToast({ header: `Greetings, ${account.username}!`, color: 'success', duration: 1500 })
                    }, 500)
                }, error => {
                    loading.dismiss()
                    console.log(error);
                    let header = error?.error?.error?.what ? error?.error?.error?.what : error.error
                    this.system.showToast({ header, color: 'danger' })
                })
            })
        }
        catch(err : any){
            // loading.dismiss()
            console.log(err);
            this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger' })
        }
    }

    async openLogin(){
        this.modal.dismiss().then(async ()=>{
            const modal = await this.modal2.create({
                component: LoginComponent,
                swipeToClose: true,
                cssClass: 'main-modal'
            });
            return await modal.present(); 
        })
	}

    // async generateLocalKey() {
    //     const loading = await this.load.create({ spinner: 'crescent', message: `Generating Keys...`, cssClass: 'loading-overlay', backdropDismiss: false });
    //     await loading.present();

    //     let username = this.metamask_register_form.value.username;
    //     let passphrase = this.metamask_register_form.value.username;
    //     // let passphrase = this.metamask_register_form.value.custom_passphrase && this.metamask_register_form.value.passphrase 
    //     //     ? 
    //     //     this.metamask_register_form.value.passphrase 
    //     //     : 
    //     //     this.metamask_register_form.value.username
    //     // console.log('Pass', passphrase);

    //     try {
    //         let ethereum = (window as any).ethereum;
    //         let provider = new ethers.providers.Web3Provider(ethereum);
    //         let signer = provider.getSigner();
    //         let message = md5(passphrase);
    //         let signature = await signer.signMessage(message);
    
    //         this.localKeyFromMetamask = await this.crypto.generateKeySeed(username, signature);
    
    //         // console.log("signature", signature);
    //         // console.log("keys", this.localKeyFromMetamask);
    //         loading.dismiss()
    //         this.registerMetamask(signature, await signer.getAddress())
    //     }
    //     catch (err : any | MetamaskError) {
    //         loading.dismiss()
    //         this.system.showToast({ header: "MetaMask Error", message : err.message.replace('MetaMask Message Signature: ', ''), color: 'danger' });
    //         console.log(err.message ? err.message : err);
    //     }
    // }

    // async registerMetamask(signature: string, pub_address: string) {
    //     const loading = await this.load.create({ spinner: 'crescent', message: `Creating Account...`, cssClass: 'loading-overlay', backdropDismiss: false });
    //     await loading.present();

    //     //check network
    //     // if (!this.connect.isMainNetwork) {
    //     //     this.system.showToast({ header: "Invalid network", color: 'danger' });
    //     // loading.dismiss()//     
    //     // return;
    //     // }
    //     //check username        
    //     let username = this.metamask_register_form.value.username.toLowerCase();
    //     let email = this.metamask_register_form.value.email

    //     if (!username || !email) {
    //         this.system.showToast({ header: "Please enter username & email", color: 'danger' });
    //         loading.dismiss()
    //         return;
    //     }
    //     //check address
    //     let account = this.address;
    //     if (!account) {
    //         this.system.showToast({ header: "Please link address first", color: 'danger' });
    //         loading.dismiss()
    //         return;
    //     }
    //     account = account.toLowerCase();
    //     let key: Key = this.localKeyFromMetamask;
    //     if (!key) {
    //         this.system.showToast({ header: "Please generate local key first", color: 'danger' });
    //         loading.dismiss()
    //         return;
    //     }

    //     // console.log({ username, account, pkey: key.pub_key });
    //     try {
    //         let buff = Serializer.encode({ object: Name.from(username) }); //user    
    //         buff.append(Serializer.encode({ object: account })); //address    
    //         //LOCAL PUBLIC KEY IS SET HERE
    //         buff.append(Serializer.encode({ object: PublicKey.from(key.pub_key) })); //pkey

    //         let tmp = Serializer.encode({
    //             object: [{
    //                 account: "wire.users",
    //                 name: "addmetauser",
    //                 authorization: [{
    //                     actor: "wire.users",
    //                     permission: "active"
    //                 }],
    //                 data: buff
    //             }],
    //             type: 'action[]',
    //             customTypes: [Action]
    //         });
    //         let actions = tmp.array;

    //         let ethereum = (window as any).ethereum;
    //         let provider = new ethers.providers.Web3Provider(ethereum);
    //         let signer = provider.getSigner();
    //         const abi = [
    //             "function pushEosTransaction(uint64 rp, bytes actions) returns (boolean)",
    //             "function getRp() view returns (uint64)"
    //         ];
    //         const address = "0x0000000000000000000000000000000000000000";
    //         let contract = new ethers.Contract(address, abi, signer);
    //         let nameValue = eosjsAccountName.nameToUint64("wire.users");
    //         let rp = BigNumber.from(nameValue);

    //         let nonce = await this.auth.getNonce(username);

    //         let message = {
    //             nonce,
    //             pub_key: key.pub_key,
    //             username,
    //             signature,
    //             address: pub_address
    //         }
    //         let msg = this.crypto.encrypt(message);
    //         // console.log(msg, message);

    //         await this.auth.registerMetamask(msg, username, email);
    //         // return;

    //         //this metamask call will create an account once confirmed, please use test names only
    //         //error messages from eos network will be handled in catch block
    //         //for example: eth address already in use, username already taken, etc
    //         //accounts are listed in https://local.bloks.io/account/wire.users?loadContract=true&tab=Tables&table=accounts&account=wire.users&scope=wire.users&limit=100&nodeUrl=https%3A%2F%2Fwire.siliconswamp.info&coreSymbol=WIRE&systemDomain=eosio&hyperionUrl=https%3A%2F%2Fhyperwire.siliconswamp.info%2F
    //         //expected errors: username = fabriciowire, mmuser, mmuser2 (already exists)
            
    //         // let res = await contract.pushEosTransaction(rp, actions);

    //         //continue flow
    //         // this.market.fetchSimpleTokens('usd');

    //         let user : User = {
    //             username,
    //             firstName : "",
    //             lastName : "",
    //             email: "",
    //             // email,
    //             phone : "",
    //             DOB : undefined,
    //             currency: 'usd',
    //             location: "unknown",
    //             profileImgURL: `https://ipfs.fuego.airwire.io/QmchgtciBdayRz7zUqwj4BQURo5UFC1iZXBA7rjc78DdrP/image.png`
    //         }
            
    //         loading.dismiss()
    //         this.auth.setKey(key)
    //         this.auth.login(user)
    //         // this.discord.login(user.username)
    //         this.contract.login(key)
    //         this.modal.dismiss();
    //         this.auth.emit('login', user.username);

    //         setTimeout(() =>{
    //             this.system.showToast({ header: `Greetings, ${user.username}!`, color: 'success', duration: 1500 });
    //         }, 500);
            
    //     } catch (err: any) {
    //         loading.dismiss()

    //         if (err.code && err.code == 4001)
    //             this.system.showToast({ header: "MetaMask Error", message : err.message.replace('MetaMask Tx Signature: ', ''), color: 'danger' });
    //         else
    //             this.system.showToast({ header: "MetaMask Error", message: this.getErrorMessageFromRPC(err), color: 'danger' });

    //         console.log(err.message ? err.message : err);
    //     }
    // }

    // getErrorMessageFromRPC(e: any): string {
    //     let message = "Error occurred in WIRE network.";
    //     let substring = "[ethjs-query] while formatting outputs from RPC";
    //     if (e.message && e.message.indexOf(substring) > -1) {
    //         let parts = e.message.split("'");
    //         if (parts.length > 0) {
    //             let error = JSON.parse(parts[1]);
    //             if (error && error.value && error.value.data) {
    //                 message = error.value.data;
    //             }
    //             if (message && message.length > 1) message = message[0].toUpperCase() + message.slice(1);
    //         }
    //     }
    //     if(e.error?.error?.details[0]?.message) {
    //         message = e.error.error.details[0].message;
    //     }
    //     message = message.replace('assertion failure with message:', '')
    //     return message;
    // }

    // async openLogin(){
    //     // this.modal.dismiss()
    //     // this.dialog.open(LoginComponent, {
    //     //     panelClass: 'secondary-modal'
    //     // });
        

    //     const modal = await this.modal2.create({
    //         component: LoginComponent,
    //         swipeToClose: true,
    //         cssClass: 'welcome-modal',
    //     });
    //     return await modal.present();  

    //     // this.dialogRef.close();
    // }

    // async register(){
    //     const loading = await this.load.create({ spinner: 'crescent', message: `Registering...`, cssClass: 'loading-overlay', backdropDismiss: false });
    //     await loading.present();

    //     // this.market.fetchSimpleTokens(this.currency.toLowerCase())
    //     let username = this.register_form.value.username.toLowerCase()
    //     let email = this.register_form.value.email
    //     let password = this.register_form.value.password

    //     try {
    //         this.crypto.generateKeySeed(username, password).then((key : Key) => {
    //             let user : User = {
    //                 username,
    //                 // password : bcrypt.hashSync(this.password_form.value.password, 10),
    //                 // password : this.password_form.value.password,
    //                 firstName : this.first_name ?  this.first_name : '',
    //                 lastName : this.last_name ? this.last_name : '',
    //                 email : email ? email : '',
    //                 phone : this.phone ? this.phone : '',
    //                 DOB : this.DOB ? this.DOB : undefined,
    //                 currency: this.currency ?  this.currency.toLowerCase() : 'usd',
    //                 location: 'unknown',
    //                 key: key.pub_key,
    //                 profileImgURL: this.profileImgURL
    //             }

    //             this.http.post(WIRE_API + "/createAccount", user).subscribe((res:any) => {
    //                 console.log(res);
    //                 loading.dismiss()
    //                 delete user.key
    //                 this.auth.setKey(key)
    //                 this.auth.login(user)
    //                 // this.discord.login(user.username)
    //                 this.contract.login(key)
    //                 // this.router.navigateByUrl('/home');
    //                 this.modal.dismiss()
    //                 this.auth.emit('login', user.username);

    //                 setTimeout(() =>{
    //                     this.system.showToast({ header: `Greetings, ${user.firstName}!`, color: 'success', duration: 1500 })
    //                 }, 500)
    //             }, error => {
    //                 console.log(error);
    //                 this.system.showToast({ header: `Something went wrong...`, message: error?.error?.error?.what, color: 'danger' })
    //             })
    //         })
    //     }
    //     catch(err : any){
    //         loading.dismiss()
    //         console.log(err);
    //         this.system.showToast({ header: `Something went wrong...`, message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger' })
    //     }

    // }
}



