import { Injectable } from "@angular/core";
import { PartialObserver, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertController, LoadingController, NavController } from "@ionic/angular";
import { AuthService, User } from "./auth.service";
import { API } from "../_constants/constants";
import { ContractService } from "./contract.service";
import { SystemService } from "./system.service";

@Injectable()
export class MetaGuideService  {
    private events : any = {};

    public filters : MetaGuideFilter[] = [
		{   title: 'Categories',
			options: [
				{ name : 'Blockchain',	checked: false },
				{ name : 'Crypto',		checked: false },
				{ name : 'Development',	checked: false },
				{ name : 'Marketing',	checked: false },
				{ name : 'Business',	checked: false },
				{ name : 'Photography',	checked: false },
				{ name : 'Play To Earn',checked: false },
				{ name : 'Trading',		checked: false },
				{ name : 'DeFi',		checked: false },
				{ name : 'Security',	checked: false },
				{ name : 'NFTs',		checked: false },
				{ name : 'History',		checked: false }
			]
		}
	]
	public metaguideSearch? : string 

	public sampleMetaGuides : MetaGuide[] = [
		{
			name : 'Esther Howard',
			username : 'ehoward78',
			title : 'Data',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		{
			name : 'Eleanor Pena',
			username : 'epena1930',
			title : 'UI/UX Designer',
			profilePic: 'https://t4.ftcdn.net/jpg/02/19/63/31/360_F_219633151_BW6TD8D1EA9OqZu4JgdmeJGg4JBaiAHj.jpg',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		{
			name : 'Devon Lane',
			username : 'dlane42',
			title : 'Project Manager',
			profilePic: 'https://t4.ftcdn.net/jpg/03/30/25/97/360_F_330259751_tGPEAq5F5bjxkkliGrb97X2HhtXBDc9x.jpg',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		{
			name : 'Floyd Miles',
			username : 'milesf7',
			title : 'Psychologist',
			profilePic: 'https://media.istockphoto.com/photos/smiling-black-man-in-suit-posing-on-studio-background-picture-id1201144328?k=20&m=1201144328&s=612x612&w=0&h=U2DJlDOnhJNvQziVvorKPIlqygqqpNL1s_rxqlLUhDo=',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
	]
	public sampleMetaGuideDictionary : any = {
		ehoward78 : {
			name : 'Esther Howard',
			username : 'ehoward78',
			title : 'Data',
			profilePic: 'https://ipfs.airwire.io/ipfs/QmSTAS2XKevVmjFBeVi9LoWJFz9MRf2nTRrZu3zzaxdgbW',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			website : 'www.estherhoward.org',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		epena1930 : {
			name : 'Eleanor Pena',
			username : 'epena1930',
			title : 'UI/UX Designer',
			profilePic: 'https://t4.ftcdn.net/jpg/02/19/63/31/360_F_219633151_BW6TD8D1EA9OqZu4JgdmeJGg4JBaiAHj.jpg',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			website : 'www.estherhoward.org',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		dlane42: {
			name : 'Devon Lane',
			username : 'dlane42',
			title : 'Project Manager',
			profilePic: 'https://t4.ftcdn.net/jpg/03/30/25/97/360_F_330259751_tGPEAq5F5bjxkkliGrb97X2HhtXBDc9x.jpg',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			website : 'www.estherhoward.org',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
		milesf7: {
			name : 'Floyd Miles',
			username : 'milesf7',
			title : 'Psychologist',
			profilePic: 'https://media.istockphoto.com/photos/smiling-black-man-in-suit-posing-on-studio-background-picture-id1201144328?k=20&m=1201144328&s=612x612&w=0&h=U2DJlDOnhJNvQziVvorKPIlqygqqpNL1s_rxqlLUhDo=',
			interests : ['design', 'education', 'business'],
			bio : 'Nulla tortor suspendisse velit, cum. Mi eget id odio at biben placerat massa. Orci, quisque risus faucibus sem. A et risus diam mauris, scelerisque. Ornare molestie volutpat arcu viverra auctor vitae sed. Duis et et aliquet sit ultricies quam leo lacus, morbi. Sollicitudin mattis molestie velit sit mattis. Orci mi sed fames tortor, nunc libero, duis.',
			website : 'www.estherhoward.org',
			socials : [ 
				'facebook.com/ehoward78',
				'linkedin.com/ehoward78',
				'youtube.com/ehoward78',
				'mysomething.com'
			]
		},
	}

    private metaGuides : { key : string } | {} = {}

    constructor(
        private http : HttpClient,
		public auth : AuthService,
		private contract : ContractService,
		private alert : AlertController,
		private system : SystemService,
		private load : LoadingController,
		private nav : NavController ){

		this.storeMetaGuides()

    }

	get metaguides(): { key : string } | any {
        return this.metaGuides as { key : string }
    }
	get metaguidesFlip(): { key : string } | any {
		return this.objectFlip(this.metaguides) as { key : string }
    }
    
    isMetaGuide(username : string): boolean {
        return Object.keys(this.metaGuides).includes(username)
    }
    storeMetaGuides(){
        this.http.get<{ key : string }>(API + 'metaguides').subscribe((res : { key : string })=>{
            this.metaGuides = res
			this.emit('metaguides-loaded', res)
        })
    }

	getMetaGuides(): Promise<MetaGuide[]>{
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'getMetaGuide/').subscribe((res : any)=>{
				// console.log(res);
				resolve(res)
			})
			// resolve(this.sampleMetaGuides)
		})
	}
	getMetaGuide(username : string): Promise<MetaGuide>{
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'getMetaGuide/' + username).subscribe((res : any)=>{
				// console.log(res);
				resolve(res)
			})
			// resolve(this.sampleMetaGuideDictionary[username])
		})
	}

	async getMetaGuideFilters(): Promise<MetaGuide[]>{
		let filters : any = {}

		await this.filters.forEach(f => { 
			let arr = f.options.filter(o => o.checked).map(m => m.name) 
			if (arr.length) filters[f.title] = arr
		});

		if (this.metaguideSearch) filters['Search'] = this.metaguideSearch

		return new Promise((resolve, reject)=>{
			// resolve(this.sampleCourses)
			this.http.post(API + 'getMetaGuideFilters/', {filters}, this.auth.authHeader).subscribe((res : any)=>{
				this.emit('get-metaguides', res as MetaGuide[])
				resolve(res as MetaGuide[])
			}, err => {
				reject(err)
			})
		})
	}

	getFavMetaGuides() : Promise<MetaGuide[]>{
		return new Promise((resolve, reject)=>{
			this.http.get<MetaGuide[]>(API + 'favMetaGuide/' + this.auth.user?.username).subscribe((res : MetaGuide[])=>{
				// console.log(res);
				resolve(res)
			})
			// resolve(this.sampleMetaGuideDictionary[username])
		})
	}

	availableRewards(username : string): Promise<number>{
		return new Promise((resolve, reject)=>{
			this.http.get(API + 'availableRewards/' + username).subscribe((res : any)=>{
				resolve(res.availableRewards)
			})
			// resolve(this.sampleMetaGuideDictionary[username])
		})
	}

    public clearFilters(){
        this.filters.map((filter)=>{
            filter.options.map((opt)=>{
                opt.checked = false
            })
        })
		this.metaguideSearch = undefined
		this.emit('clear-meta-filters')
		this.getMetaGuideFilters()
    }

	public openMetaGuide(username : string){
        this.nav.navigateForward(['metaguides', username])
	}

	favorite(metaguide : string){
		this.http.post(API + 'favorite', { metaguide }, this.auth.authHeader).subscribe((res)=>{
			// console.log(res);
		})
	}

	checkFavorite(metaguide : string) : Promise<boolean>{
		return new Promise((resolve, reject)=>{
			this.http.get<{ favorited : boolean }>(API + 'favMetaGuide/' + this.auth.user.username + '/' + metaguide)
			.subscribe((res : { favorited : boolean })=>{
				console.log(res);
				resolve(res.favorited)
			})
		})
	}

	updateMetaGuide(user : User){
		return new Promise((resolve, reject)=>{
			if (this.isMetaGuide(this.auth.user.username)) {
				this.http.post(API + 'updateMetaGuide', { user }, this.auth.authHeader).subscribe((res : any)=>{
					resolve(res)
				}, err => {
					reject(err)
				})
			}
			else resolve(true)
		})
	}

	getMetaGuideFavCount( metaguide : string ) : Promise<number>{
		return new Promise((resolve, reject)=>{
			this.http.get<number>(API + 'getMetaGuideFavCount/' + metaguide).subscribe((res : number)=>{
				resolve(res)
			})
		})
	}

	async applyMetaGuide(){
		if (this.auth.user){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Apply to Become a MetaGuide',
				message: `Below, please explain a little about yourself, your expereince, and why you'd like to become a MetaGuide. (In addition, we reccomend that you fill out your profile info in Settings for a better chance at acceptance)`,
				inputs: [
					{
						name: 'message',
						id: 'message',
						type: 'textarea',
						placeholder: `Application Message`,
						label: 'Application Message'
					}
				],
				buttons: [{
					text: 'Apply',
					handler: async (data) => {
						// console.log(data.message);
						
						const loading = await this.load.create({ spinner: 'crescent', message: `Applying...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<any>(API + 'applyMetaGuide', { message: data.message ? data.message : '' }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Application Submitted', color: 'success'})
							this.emit('update-application')
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error submitting application...', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}

	async cancelApplication(){
		if (this.auth.user){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Cancel MetaGuide Application',
				message: `Are you sure you want to cancel your MetaGuide application? You can re-apply at any time.`,
				buttons: [{
					text: 'Cancel Application',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Canceling...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post<any>(API + 'cancelApplication', {}, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'Application Cancelled', color: 'success'})
							this.emit('update-application')
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error cancelling application...', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
						})
					}
				},{
					text: 'Nevermind',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}

	async approveMetaGuide(username : string){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Approve MetaGuide Application',
				message: `Are you sure you want to approve this MetaGuide Application? ${username} will be able to upload courses.`,
				buttons: [{
					text: 'Approve',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Approving...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post(API + 'approveMetaGuide', { username }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'MetaGuide Application Approved!', color: 'success'})
							this.emit('update-metaguide')
							setTimeout(()=>{
								this.nav.navigateForward(['metaguides', username])
							}, 500)
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error approving course...', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}
	async denyMetaGuide(username : string){
		if (this.auth.user && this.auth.user.username == 'metaadmin'){
			const alert = await this.alert.create({
				cssClass: 'my-custom-class',
				header: 'Deny MetaGuide Application',
				message: `Are you sure you want to deny this MetaGuide application?`,
				buttons: [{
					text: 'Deny',
					handler: async () => {
						const loading = await this.load.create({ spinner: 'crescent', message: `Denying...`, cssClass: 'loading-overlay', backdropDismiss: false });
						await loading.present();

						this.http.post(API + 'denyMetaGuide', { username }, this.auth.authHeader).subscribe((res : any)=>{
							loading.dismiss()
							this.system.showToast({ header: 'MetaGuide Application Denied', color: 'success'})
							this.emit('update-metaguide')
							// this.nav.navigateForward(['courses', course_id])
						}, err =>{
							loading.dismiss()
							console.log(err);
							this.system.showToast({ header: 'Error denying application...', message: err.hasOwnProperty('error') ? err.error : JSON.parse(err), color: 'danger'})
						})
					}
				},{
					text: 'Cancel',
					role: 'cancel',
				}]
			});
			alert.present();
		}
		else console.log('Not Authorized')
	}

	checkApplication(username : string){
		return new Promise((resolve, reject)=>{
			this.http.get<boolean>(API + 'checkApplication/' + username).subscribe((res : boolean)=>{
				resolve(res)
			})
		})
	}

	objectFlip(obj : any) {
		return Object.keys(obj).reduce((ret : any, key : any) => {
			ret[obj[key]] = key;
			return ret;
		}, {});
	}

    on(event : string) {
        let sub = new Subject()
        if (this.events[event] && this.events[event].length)
            this.events[event].push(sub)
        
        else this.events[event] = [sub]
        return sub
    }
    emit(event : string, data?: any) {
        if (this.events[event])
            for (let ev of this.events[event])
                ev.next(data);
    }
} 

export interface MetaGuide {
    name : string
    username : string
    title : string
    profilePic : string
    interests : string[] | string
    bio : string

	availableRewards? : number
    socials? : string[]
    courseViews? : number
    totalCourses? : number
    rewardPoints? : number
}

export interface MetaGuideFilter {
    title: string
    options: MetaGuideFilterOption[]
}
export interface MetaGuideFilterOption {
    name : string
    checked : boolean
}


// export interface Social {
//     facebook? : string
//     twitter? : string
//     linkedin? : string
//     youtube? : string
//     medium? : string
//     other? : string
// }