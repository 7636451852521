import { Component, OnInit } from '@angular/core';
// import { LoadingController, AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { SystemService } from '../../_services/system.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Account, AuthService, User } from '../../_services/auth.service';
import { API } from '../../_constants/constants';
import { CryptoService, Key } from '../../_services/crypto.service';
import { HttpClient } from '@angular/common/http';
import { ConnectService, MetamaskError } from "../../_services/connect.service";
import { ethers } from "ethers";
// import md5 from 'md5';
const md5 = require('md5');

import { RegisterComponent } from '../register/register.component';
import { ContractService } from 'src/app/_services/contract.service';
import { DiscordService } from 'src/app/_services/discord.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';


const usernameRegex = new RegExp("[^a-z1-5]+")

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    username: string = ''
    password: string = ''

    hidePassword = true

    get canLogin(){
        return this.username && this.password && this.username != '' && this.password != ''
    }

    constructor(
        private discord : DiscordService,
        private contract : ContractService,
        public connect: ConnectService,
        private alert: AlertController,
        private crypto : CryptoService,
        public system: SystemService,
        private auth: AuthService,
        private load: LoadingController,
        private modal : ModalController,
        private modal2 : ModalController,
        private http: HttpClient) {
    }

    ngOnInit() {
    }

    async login(){
        if (!this.canLogin){
            this.system.showToast({ header: 'Username & Password required!', color: 'warning'})
            return
        }

        let username = this.username.toLowerCase()
        let password = this.password

        const loading = await this.load.create({ spinner: 'crescent', message: `Logging in...`, cssClass: 'loading-overlay', backdropDismiss: false });
        await loading.present();

        this.crypto.generateKeySeed(username, password).then((key : Key) => {
            this.http.post(API + "login", { username, key: key.pub_key }).subscribe((res : any) => {
                // console.log(res);
                loading.dismiss()
                let user : Account = res
                // user.profileImgURL = res.profileImgURL
                this.auth.login(user)
                // this.discord.login(user.username)
                this.auth.setKey(key)
                this.contract.login(key)
                this.modal.dismiss()

                setTimeout(() => {
                    this.auth.emit('login', user.username)
                    this.system.showToast({ header: `Login Success`, message: `Welcome, ${user.username}!`, color: 'success' })
                }, 500)

            }, (error:any) => {
                console.log(error.error);
                loading.dismiss()
                this.system.showToast({ header: error.error, color: 'danger' })
            })
        })
    }

    async openRegister(){
        this.modal.dismiss().then(async ()=>{
            const modal = await this.modal2.create({
                component: RegisterComponent,
                swipeToClose: true,
                cssClass: 'main-modal'
            });
            return await modal.present(); 
        })
	}
}
