import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Course } from 'src/app/_services/course.service';
import { MetaGuide, MetaGuideService } from 'src/app/_services/metaguide.service';
import { SystemService } from 'src/app/_services/system.service';

@Component({
  selector: 'app-metaguide-card',
  templateUrl: './metaguide-card.component.html',
  styleUrls: ['./metaguide-card.component.scss'],
})
export class MetaGuideCardComponent implements OnInit {
	@Input() name! : string
	@Input() metaguide? : MetaGuide
	@Input() noHover : boolean = false
	@Input() loading : boolean = false

	constructor(
		private nav : NavController,
		private MetaGuide : MetaGuideService,
		public system : SystemService ){}

	ngOnInit(){
		if (!this.metaguide && this.name) this.MetaGuide.getMetaGuide(this.name).then((res)=>{
			this.metaguide = res
		})
		// console.log(this.course);
	}

	openMetaGuide(){
		if (this.metaguide && !this.noHover)
			this.nav.navigateForward(['metaguides', this.metaguide.username])
	}

}
