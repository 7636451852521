import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from '@angular/material/input'; 

@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        FormsModule,
    ],
    exports: [
        
    ],
    providers: [
    ]
})
export class MaterialModule {}